<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control special-margin">
        <b-button type="is-primary" @click="openAddArticle()"
          >Agregar artículo</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button type="is-primary" @click="openCopyArticle()"
          >Agregar artículo usando otro de base</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button type="is-primary" @click="openProviderArticles()"
          >Ver relación artículos proveedores</b-button
        >
      </div>

      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.importArticles === 'S' &&
            permissions &&
            permissions.main.importArticles === 'S'
          "
          >Importar artículos</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules &&
            companyPaymentPlanModules.main.exportArticles === 'S' &&
            permissions &&
            permissions.main.exportArticles === 'S'
          "
          >Exportar artículos</b-button
        >
      </div>
      <div class="control special-margin">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Artículos: <b>{{ articles.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      paginated
      :total="articles.length"
      :per-page="perPage"
      :loading="loading"
      :data="articles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedArticles"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Articles"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_ART}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      checkbox-position="left"
      sort-icon="arrow-up"
      default-sort-direction="asc"
      default-sort="CLAVE_ART"
    >
      <template #empty>
        <div class="has-text-centered">No hay articulos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          :searchable="column.searchable"
          :sortable="column.sortable"
          v-bind="column"
          :visible="column.display"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <p
            v-if="!checkIfValid(props.row[column.field])"
            @click="openPage(column.label)"
          >
            {{ "Sin información" }}
          </p>
          <p @click="openPage(column.label)">{{ props.row[column.field] }}</p>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <AddArticleModal :articleInformation="props.row" />
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import AddArticleModal from "./AddArticleModal";
import ImportFileModal from "../../Global/components/ImportFileModal";
import ProvidersListArticles from "../../Purchases/components/ProvidersListArticles";
import SelectArticle from "../../Sales/components/SelectArticle";
import { EventBus } from "@/event-bus";
import moment from "moment";
export default {
  name: "ArticlesList",
  components: {
    AddArticleModal,
  },
  data() {
    return {
      perPage: 50,
      checkedArticles: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "lastBuyFormat",
          label: "Ultima compra",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateBuy,
        },
        {
          field: "lastSaleFormat",
          label: "Ultima venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateSale,
        },
        {
          field: "totalQuantity",
          label: "Inventario total",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      loading: false,
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETARTICLES"));
    allPromises.push(this.$store.dispatch("GETBRANDS"));

    await Promise.all(allPromises);
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    async reloadInformation() {
      this.loading = true;
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETBRANDS"));
      allPromises.push(this.$store.dispatch("GETARTICLES"));

      await Promise.all(allPromises);

      this.loading = false;
      this.refreshLoading = false;
    },
    confirmDeleteArticles() {
      if (this.checkedArticles.length > 0) {
        this.$buefy.dialog.confirm({
          title: "Borrar proveedores",
          message:
            "¿Estas seguro que deseas <b>borrar</b> los proovedores seleccinados? Esta acción no puede ser cambiada.",
          confirmText: "Borrar proveedores",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            this.$store
              .dispatch("DELETEPROVIDERS", this.checkedProviders)
              .then((response) => {
                if (response === "Success") {
                  this.$buefy.dialog.confirm({
                    title: "Éxito",
                    message:
                      "Se borraron exitosamente los proveedores seleccionadas",
                    confirmText: "Entendido",
                    type: "is-success",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                } else {
                  this.$buefy.dialog.confirm({
                    title: "Error",
                    message:
                      "Hubo un error al borrar los proveedores en la base de datos",
                    confirmText: "Entendido",
                    type: "is-danger",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                }
              }),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay proveedores seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          onConfirm: () => {},
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddArticle() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddArticleModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openProviderArticles() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ProvidersListArticles,
        props: {
          specialComponent: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "articles",
          nameImport: "Articulos",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    openPage(label) {
      if (label === "Inventario total") {
        this.$router
          .push({ path: "/General/WarehousesArticles" })
          .catch(() => {});
        this.$store.dispatch(
          "CHANGENEWHINT",
          "En esta pantalla podrás visualizar el inventario de cada artículo en tus almacenes"
        );
      }
    },
    sortByDateSale(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FE_ULTVTA).getTime() || -Infinity) -
          (new Date(a.FE_ULTVTA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FE_ULTVTA).getTime() || -Infinity) -
          (new Date(b.FE_ULTVTA).getTime() || -Infinity)
        );
      }
    },
    sortByDateBuy(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FE_ULTCMP).getTime() || -Infinity) -
          (new Date(a.FE_ULTCMP).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FE_ULTCMP).getTime() || -Infinity) -
          (new Date(b.FE_ULTCMP).getTime() || -Infinity)
        );
      }
    },
    startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de artículos",
        message: `¿Desea confirmar la exportación de los artículos a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    exportData() {
      let payload = {
        data: this.articles.map((singleArticle) => {
          const singleArticleFormatted = {};

          singleArticleFormatted["CLAVE DEL ARTICULO (Requerido)"] =
            singleArticle.CLAVE_ART ? singleArticle.CLAVE_ART : "";
          singleArticleFormatted["DESCRIPCION (Requerido)"] =
            singleArticle.DESCRIPCION ? singleArticle.DESCRIPCION : "";
          singleArticleFormatted["ACTIVO PARA VENTA (Requerido)"] =
            singleArticle.ACTIVO_VENTA ? "S" : "N";
          singleArticleFormatted["ACTIVO PARA COMPRAS (Requerido)"] =
            singleArticle.ACTIVO_COMPRA ? "S" : "N";
          singleArticleFormatted[
            "ACTIVO PARA COMPRAS INTELIGENTES (Requerido)"
          ] = singleArticle.ACTIVO_COMPRA_INTELIGENTE ? "S" : "N";
          singleArticleFormatted[
            "CLAVE PARA CLASIFICACION INTERNA (Opcional)"
          ] = singleArticle.CLAVE_CLAS ? singleArticle.CLAVE_CLAS : "";
          singleArticleFormatted["CODIGO SAT (Requerido)"] =
            singleArticle.CLAVE_SAT_DESC
              ? singleArticle.CLAVE_SAT_DESC.split(" ")[0]
              : "";
          singleArticleFormatted["UNIDAD DE MEDIDA BASE (Requerido)"] =
            singleArticle.UNIDAD_EMP
              ? singleArticle.UNIDAD_EMP.DESCRIP_MED
              : "";
          singleArticleFormatted["URL (Opcional)"] = singleArticle.URL
            ? singleArticle.URL
            : "";
          singleArticleFormatted["OBSERVACIONES (Opcional)"] =
            singleArticle.OBSERVACIONES ? singleArticle.OBSERVACIONES : "";
          singleArticleFormatted["FECHA ULTIMA VENTA (Opcional)"] =
            singleArticle.FE_ULTVTA
              ? moment(singleArticle.FE_ULTVTA).format("DD/MM/YYYY")
              : "";
          singleArticleFormatted["FECHA ULTIMA COMPRA (Opcional)"] =
            singleArticle.FE_ULTCMP
              ? moment(singleArticle.FE_ULTCMP).format("DD/MM/YYYY")
              : "";
          singleArticleFormatted["PESO (KGS) (Opcional)"] =
            singleArticle.CANT_PESO ? singleArticle.CANT_PESO : 0;
          singleArticleFormatted["VOLUMEN (MT3) (Opcional)"] =
            singleArticle.CANT_VOL ? singleArticle.CANT_VOL : 0;
          singleArticleFormatted["COSTO MINIMO (Requerido)"] =
            singleArticle.COSTO_MIN_VTA ? singleArticle.COSTO_MIN_VTA : 0;
          singleArticleFormatted["PORCENTAJE DE IVA (Requerido)"] =
            singleArticle.CANT_IVA ? singleArticle.CANT_IVA : 0;
          singleArticleFormatted["PORCENTAJE DE IEPS (Requerido)"] =
            singleArticle.CANT_IEPS ? singleArticle.CANT_IEPS : 0;
          singleArticleFormatted["CODIGO DE BARRAS (Opcional)"] =
            singleArticle.CODIGO_BARRAS ? singleArticle.CODIGO_BARRAS : "";
          singleArticleFormatted["CLAVE DE LA MARCA (Opcional)"] =
            singleArticle.CLAVE_MARCA ? singleArticle.CLAVE_MARCA : "";
          // Category fields of the article
          singleArticleFormatted["CLAVE DE LA FAMILIA (Opcional)"] = "";
          if (singleArticle.CLAVE_FAM) {
            for (const singleCategory of this.categories) {
              if (singleCategory._id === singleArticle.CLAVE_FAM) {
                singleArticleFormatted["CLAVE DE LA FAMILIA (Opcional)"] =
                  singleCategory.CLAVE_FAM;
              }
            }
          }

          singleArticleFormatted["CLAVE DE LA  SUBFAMILIA (Opcional)"] = "";
          if (singleArticle.CLAVE_SFAM) {
            for (const singleCategory of this.sCategories) {
              if (singleCategory._id === singleArticle.CLAVE_SFAM) {
                singleArticleFormatted["CLAVE DE LA  SUBFAMILIA (Opcional)"] =
                  singleCategory.CLAVE_SFAM;
              }
            }
          }

          singleArticleFormatted["CLAVE DE LA SUBSUBFAMILIA (Opcional)"] = "";
          if (singleArticle.CLAVE_SSFAM) {
            for (const singleCategory of this.sSCategories) {
              if (singleCategory._id === singleArticle.CLAVE_SSFAM) {
                singleArticleFormatted["CLAVE DE LA SUBSUBFAMILIA (Opcional)"] =
                  singleCategory.CLAVE_SSFAM;
              }
            }
          }

          singleArticleFormatted["CLAVE DE LA SUBSUBSUBFAMILIA (Opcional)"] =
            "";
          if (singleArticle.CLAVE_SSSFAM) {
            for (const singleCategory of this.sSSCategories) {
              if (singleCategory._id === singleArticle.CLAVE_SSSFAM) {
                singleArticleFormatted[
                  "CLAVE DE LA SUBSUBSUBFAMILIA (Opcional)"
                ] = singleCategory.CLAVE_SSSFAM;
              }
            }
          }

          return singleArticleFormatted;
        }),
        name: "articulos-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    openCopyArticle() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SelectArticle,
        props: {
          articles: this.articles,
          priceLists: [],
          isArticleList: true,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: false,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.activeDimensionsWeight = true;
        articleWithProps.disableIva = true;
        articleWithProps.activeFamily = true;
        articleWithProps.activeSFamily = true;
        articleWithProps.activeSSFamily = true;
        articleWithProps.activeSSSFamily = true;
        articleWithProps.shippingActive = true;
        articleWithProps.tarrifActive = true;
        articleWithProps.retentionActive = true;
        articleWithProps.totalQuantity = 0;
        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }
        if (singleArticle.FE_ULTCMP) {
          articleWithProps.lastBuyFormat = moment(
            singleArticle.FE_ULTCMP
          ).format("DD-MM-YYYY HH:mm");
        }
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }
        return articleWithProps;
      });
    },
    brands() {
      return this.$store.getters.BRANDS;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    sCategories() {
      return this.$store.getters.SUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    sSCategories() {
      return this.$store.getters.SUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    sSSCategories() {
      return this.$store.getters.SUBSUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    categories() {
      /**
       * Map main categories with selected sub categories
       */
      return this.$store.getters.CATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
